export const environment = {
	COMPANY_NAME: 'evollo',
	production: true,
	APP_NAME: 'QUALITY',
	apiUrl: 'https://vivo-quality.api.evollo.cloud',
	accountUrl: 'https://vivo-account.api.evollo.cloud',
	integrationUrl: 'https://vivo-integration.api.evollo.cloud',
	genAiUrl: 'https://genai-integrator-api.evollo.cloud:8485',
	features: {
		enabledAverageGrade: true,
		enabledChat: false,
		enableQualityDashboard: false,
		enableInteractionSentiment: false,
		enableSupport: false,
		enableFaq: false,
		homepage: '/interactions',
		cycleAsContactDate: false, // false = data de monitoria || true = data de contato
		enableInteractionMonitorCycle: true,
		aiEnabled: false,
		aiInsightsEnabled: false,
		aiInteractionEnabled: false,
		trainingUrl: null,
		genAiOrganizationFilter: false,
		accessLogEnabled: false,
		initialInteractionDateFilter: true,
	},
	apps: {
		id: 'https://vivo.id.evollo.com.br',
		admin: 'https://vivo.admin.evollo.com.br',
	},
	firebaseConfig: {
		apiKey: "AIzaSyDuZYbwOh_vxbnmv8NKXiljM4EfNvzWerw",
		authDomain: "quality-ui.firebaseapp.com",
		projectId: "quality-ui",
		storageBucket: "quality-ui.firebasestorage.app",
		messagingSenderId: "1015646958751",
		appId: "1:1015646958751:web:cad29cdde2882247101eaa",
		measurementId: "G-B5135PSD8K"
	}
};
